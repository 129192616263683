.container {
  border: solid 2px black;
  border-radius: 25px;
  margin-right: 25px;
  padding: 20px;
  text-align: center;
}

.title {
  background-color: black;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
  text-align: center;
  width: fit-content;
}

.input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  border-bottom-style: dotted;
  text-align: center;
}

.text {
  min-height: 32px;
}
