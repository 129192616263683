.container {
  border: solid 1px black;
  text-align: center;
}

.input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  border-bottom-style: dotted;
  text-align: center;
}

.text {
  min-height: 32px;
}
